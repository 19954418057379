<template>
  <div class='error'>
    <layout-header-sp />
    <main class='error__main'>
      <section class='error__main__modal'>
        <h3>{{ setInfo('title') }}</h3>
        <p>{{ setInfo('text') }}</p>
        <button-normal>
          <router-link :to='{ name: "Dashboard" }'>
            トップページに戻る
          </router-link>
        </button-normal>
      </section>
    </main>
    <layout-footer-sp />
  </div>
</template>

<script>
import LayoutHeaderSp from '@/components/sps/LayoutHeaderSp.vue';
import LayoutFooterSp from '@/components/sps/LayoutFooterSp.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';

export default {
  components: {
    LayoutHeaderSp,
    LayoutFooterSp,
    ButtonNormal,
  },
  beforeRouteLeave() {
    if (this.isNotFound()) {
      this.$router.back(2);
    } else {
      this.$router.back();
    }
  },
  data() {
    return {
      pageInfoList: [
        {
          title: 'Error',
          text: 'ページの読み込み中にエラーが発生しました',
        },
        {
          title: 'NotFound',
          text: '該当のページは見つかりませんでした',
        },
      ],
    };
  },
  methods: {
    setInfo(target) {
      return this.pageInfoList.filter((pageInfo) => pageInfo.title
        === this.$route.name.replace('Sp', ''))[0][target];
    },
    isNotFound() {
      return this.$route.name.replace('Sp', '') === 'NotFound';
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.error__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.error__main__modal {
  background-color: $color-white;
  width: 500px;
  text-align: center;
  padding: 30px 30px 35px;
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  a:visited {
    color: $color-black;
  }
}
</style>
