<template>
  <div class='layout-footer-sp'>
    <div class='layout-footer-sp__content'>
      <div class='layout-footer-sp__content__left'>
        <div class='layout-footer-sp__content__left__top'>
          <icon-logo white />
          <p>サブスク型オンラインプログラミングスクールDonbler (ドンブラー)</p>
          <ul>
            <li v-for='sns in snsList' :key='sns.id'>
              <a :href='sns.link' target='_blank' rel='noopener noreferrer'>
                <icon-sns :snsName='sns.name' />
              </a>
            </li>
          </ul>
        </div>
        <ul class='layout-footer-sp__content__left__middle'>
          <li v-for='support in supportList' :key='support.id'>
            <a :href='support.link' target='_blank' rel='noopener noreferrer'>
              {{ support.name }}
            </a>
          </li>
        </ul>
        <div class='layout-footer-sp__content__left__bottom'>
          <p class='layout-footer-sp__content__left__bottom__copy-right'>©︎ Donbler 2022</p>
          <p class='layout-footer-sp__content__left__bottom__twimoji-license'>
            {{ twimojiLicense }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLogo from '@/components/atoms/icon/IconLogo.vue';
import IconSns from '@/components/atoms/icon/IconSns.vue';

export default {
  components: {
    IconLogo,
    IconSns,
  },
  data() {
    return {
      isActive: {
        questionBot: false,
      },
      snsList: [
        {
          name: 'twitter',
          link: 'https://twitter.com/Donbler_jp',
        },
        {
          name: 'github',
          link: 'https://github.com/donbler',
        },
      ],
      serviceList: [
        {
          name: 'お知らせ',
          link: 'https://note.com/donbler/m/md6b95a87f664',
        },
        {
          name: 'サービスガイド',
          link: 'https://note.com/donbler/m/mdbfc358debf5',
        },
        {
          name: 'ヘルプ',
          link: 'https://donbler.notion.site/6e9c3025ff544ee7bd593d3619df6b5e',
        },
        {
          name: 'お問い合わせ',
          link: process.env.VUE_APP_QUESTION_FORM_URL,
        },
      ],
      supportList: [
        {
          name: '運営会社',
          link: 'https://company.donbler.com/',
        },
        {
          name: '利用規約',
          link: 'https://donbler.notion.site/8013b863842444a5a0294d46ba629115',
        },
        {
          name: 'プライバシーポリシー',
          link: 'https://donbler.notion.site/8a02c8d27dbb4f5aabaa5146270c7768',
        },
      ],
      twimojiLicense: 'Twemoji ©️ Twitter, Inc and other contributors is licensed under CC-BY 4.0',
    };
  },
};
</script>

<style lang="scss">
.layout-footer-sp {
  background-color: $color-dark-gray;
  width: 100%;
  margin-top: auto;
}

.layout-footer-sp__content {
  padding: 30px 15px;
}

.layout-footer-sp__content__left__top {
  p {
    font-size: 12px;
    color: $color-white;
    width: 260px;
    margin-top: 5px;
  }
  ul {
    display: flex;
    margin: 0 0 0;
    li {
      a {
        color: $color-white;
        font-size: 20px;
      }

      a:hover {
        color: $color-gray;
      }
    }
    li:first-child {
      margin-right: 5px;
    }
  }
}

.layout-footer-sp__content__left__bottom__copy-right {
  font-size: 12px;
  color: $color-white;
}

.layout-footer-sp__content__left__bottom__twimoji-license {
  font-size: 10px;
  color: $color-gray;
  margin-top: 5px;
}

.layout-footer-sp__content__right {
  display: flex;
  div {
    h5 {
      font-size: 12px;
      color: $color-yellow;
    }
    ul {
      li {
        a {
          font-size: 12px;
          text-decoration: none;
          color: $color-white;
        }
        a:visited {
          color: $color-white;
        }
        a:hover {
          color: $color-gray;
        }
      }
    }
  }
}

.layout-footer-sp__content__right__service {
  margin-right: 20px;
}

.layout-footer-sp__bot__content, .layout-footer-sp__bot__button {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.bot-enter-active, .bot-leave-active {
  transform: translate(0px);
  transition: opacity .4s, transform .2s cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.bot-enter, .bot-leave-to {
  transform: translateY(15px);
  opacity: 0;
}

.button-enter-active, .button-leave-active {
  transition: opacity .4s;
}

.button-enter, .button-leave-to {
  opacity: 0;
}

.layout-footer-sp__content__left__middle {
  li {
    a {
      color: $color-white;
      text-decoration: none;
      font-size: 12px;
    }
    a:visited {
      color: $color-white;
    }
  }
}
</style>
