<template>
  <div
    class='overlay-normal' :class='addClass'
    @click='$emit("emitClickOverlay")'
  />
</template>

<script>
export default {
  props: {
    black: Boolean,
  },
  computed: {
    addClass() {
      return {
        'overlay-normal--black': this.black,
      };
    },
  },
};
</script>

<style lang="scss">
.overlay-normal {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.overlay-normal--black{
  background-color: $color-black;
  opacity: 0.6;
}
</style>
