<template>
  <i :class='addClass' />
</template>

<script>
export default {
  props: {
    snsName: String,
  },
  computed: {
    addClass() {
      return {
        fab: true,
        'fa-github': this.snsName === 'github',
        'fa-twitter': this.snsName === 'twitter',
      };
    },
  },
};
</script>

<style lang="scss">
</style>
