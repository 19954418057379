<template>
  <img class='icon-logo' :src='setLogoUrl'>
</template>

<script>
export default {
  props: {
    white: Boolean,
  },
  computed: {
    setLogoUrl() {
      const filename = this.white ? 'donbler_logo_white.png' : 'donbler_logo_black.png';
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/images/logo/${filename}`;
    },
  },
};
</script>

<style lang="scss">
.icon-logo {
  width: 150px;
}
</style>
